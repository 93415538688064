const en = {
    // SchemaDefinition titles //////////
    'dfTitle': 'Properties',
    'dfTitleSeparator': '/',
    'dfTitles': {
        'person': 'Person Properties'
    },

    // PropertyDefinition types but human-readable //////////
    'pdTypeLabels': {
        // These used when we have to resolve the type label from a PropertyDefinition.
        'false_STRING': 'Text', // As safe value if something goes wrong
        'false_STRING_false': 'Short Text',
        'false_STRING_true': 'Long Text',
        'true_STRING': 'Text List',
        'true_STRING_false': 'Short Text List',
        'false_LONG': 'Number',
        'false_DOUBLE': 'Number',
        'false_BOOLEAN': 'Boolean',
        'false_INSTANT': 'Date',
        'false_ENUMERATION': 'Enumeration',
        // Any other combination is theoretically unreachable!

        // Without list prefix.
        // 'true_STRING_false': 'Short Text List',
        'STRING_false': 'Short Text',
        'STRING_true': 'Long Text',
        'LONG': 'Number',
        'DOUBLE': 'Number',
        'BOOLEAN': 'Boolean',
        'INSTANT': 'Date',
        'ENUMERATION': 'Enumeration'
    },

    // Person domain //////////
    'person': {
        // No need to set English. Use fallback values...
        'df': {
            'pg': {},
            'pd': {
                // Basic //////////
                'name': 'Full Name'
            }
        },
        'entity': {
            'property': {},
            'group': {}
        },
        'enum': {
            'sex': {
                'UNKNOWN': 'Unknown',
                'MALE': 'Male',
                'FEMALE': 'Female',
                'OTHER': 'Other'
            },
            'maritalStatus': {
                'UNKNOWN': 'Unknown',
                'SINGLE': 'Single',
                'MARRIED': 'Married',
                'WIDOWED': 'Widowed',
                'DIVORCED': 'Divorced',
                'SEPARATED': 'Separated',
                'CIVIL_UNION': 'Civil Union',
                'DOMESTIC_PARTNER': 'Domestic Partner',
                'OTHER': 'Other'
            },
            'preferredLangKey': {
                'EL': 'Greek',
                'EN': 'English'
            },
            'sourceType': {
                'UNKNOWN': 'Unknown',
                'SYSTEM': 'System',
                'PORTAL': 'Portal',
                'API': 'API',
                'FILE_IMPORT': 'File Import',
                'ONLINE_SOURCES': 'Online Sources',
                'OFFLINE_SOURCES': 'Offline Sources'
            }
        }
    }
};

export default en;
